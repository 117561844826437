<template>
  <div class="expande-horizontal fonte bg-top" style="min-height: 100vh;">
    <div v-if="charging">
      <v-flex xs12>
        <div class="expande-horizontal centraliza column">
          <!-- <v-flex xs6>
            <LottieAnimation
              class="pa-6 pb-0"
              ref="cancel_stage"
              :loop="true"
              :animationData="require('@/apps/shared/assets/saved_stage.json')"
            />
          </v-flex> -->
          <h3>Buscando...</h3>
        </div>
      </v-flex>
    </div>
    <div v-else class="expande-horizontal centraliza column" style="min-height: 90vh;">
      <img
        src="img/logo.png"
        style="width: 150px; border-radius: 6px;"
        alt=""
      />
      <h3 class="fonte white--text px-3 mt-3 mb-3">
        Veja o andamento da sua compra
      </h3>
      <!-- <span
        class="white--text mb-3 text-center px-6"
        >O estágio atual é:
        {{
          status_type[compra.compra.tipo].filter(
            status => status.value === compra.status_do_pedido
          )[0].text
        }}</span
      > -->
      <v-list dark color="transparent" dense nav class="fonte ma-0 pa-0">
        <v-divider class="mb-2"></v-divider>
        <template v-for="(status, index) in status_type[compra.compra.tipo]">
          <v-list-item
            :key="`id-status-${status}-${index}`"
            :class="{
              'item-current-status': status.value === compra.status_do_pedido
            }"
          >
            <v-avatar
              v-if="statusChecked(status.value).status"
              class="animate__heartBeat mr-3"
              size="36"
              :color="status.color"
            >
              <v-icon color="white">
                {{ status.icon }}
              </v-icon>
            </v-avatar>
            <v-avatar
              v-else
              class="animate__heartBeat mr-3"
              size="36"
              color="grey"
            >
              <v-icon color="white">
                mdi-lock-outline
              </v-icon>
            </v-avatar>
            <v-list-item-content>
              <v-list-item-title
                :class="{
                  'font-weight-bold': status.value === compra.status_do_pedido
                }"
              >
                {{ status.text }}
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="statusChecked(status.value).status"
                :class="{
                  'white--text': status.value === compra.status_do_pedido
                }"
              >
                {{
                  $moment(statusChecked(status.value).data).format(
                    "DD/MM/YYYY [às] HH:mm"
                  )
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="
                  statusChecked(status.value).status &&
                    status.value !== compra.status_do_pedido
                "
              >
                <v-icon color="green">
                  mdi-check-circle-outline
                </v-icon>
              </v-btn>
              <v-btn
                x-small
                :color="$theme.primary"
                dark
                rounded
                text
                v-if="
                  statusChecked(status.value).status &&
                    status.value === compra.status_do_pedido &&
                    status.value !== 'concluído'
                "
              >
                <!-- Avançar -->
                <div style="width: 50px;">
                  <v-progress-linear
                    indeterminate
                    size="12"
                    class="ml-1"
                    :color_as="status.color"
                    color="#fff"
                  ></v-progress-linear>
                </div>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation
  },
  props: ["id"],
  data() {
    return {
      compra: {
        compra: {
          tipo: ""
        }
      },
      charging: true,
      status_type: {
        delivery: [
          {
            icon: "mdi-content-save-outline",
            text: "Orçamento aberto",
            value: "salvo",
            color: "#FF9800"
          },
          {
            icon: "mdi-check-circle-outline",
            text: "Orçamento Fechado",
            value: "orcamento fechado",
            color: "#4CAF50"
          },
          {
            icon: "mdi-clock-outline",
            text: "Em Corte",
            value: "em preparo",
            color: "#8BC34A"
          },
          {
            icon: "mdi-clock-outline",
            text: "Acabamento",
            value: "acabamento",
            color: "#8BC34A"
          },
          {
            icon: "mdi-rocket-outline",
            text: "Pronto",
            value: "pronto",
            color: "#2196F3"
          },
          {
            icon: "mdi-rocket-launch-outline",
            text: "Saiu p entrega",
            value: "despachado",
            color: "#2196F3"
          },
          {
            icon: "mdi-check-circle-outline",
            text: "Finalizado",
            value: "concluído",
            color: "#4CAF50"
          }
        ]
      }
    };
  },
  methods: {
    statusChecked(status) {
      let is_checked = {
        status: false,
        data: null,
        color: "red"
      };
      this.compra.historico_de_status.map(h_s => {
        if (h_s.status === status) {
          is_checked.status = true;
          is_checked.data = h_s.data;
        }
      });
      return is_checked;
    },
    getSale() {
      const self = this;
      self
        .$run(
          "transacoes/view-transaction",
          {
            _id: self.id
          },
          "client"
        )
        .then(res => {
          let transaction = res.data;
          self.compra = transaction;
          self.charging = false;
        });
    }
  },
  created() {
    if (this.id) {
      this.getSale();
    }
    setInterval(() => {
      this.getSale();
    }, 30000);
  }
};
</script>

<style>
.bg-top {
  background: #0f0c29; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0f0c29,
    #302b63,
    #24243e
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(45deg, #0f0c29, #302b63, #24243e);
}
</style>
